import React from "react";
import magento from "../api/magento";
import moment from "moment";
import { MenuItem } from "semantic-ui-react";
import QuotationPDFRed from "../components/pdf/Red/QuotationPDF";
import QuotationPDF from "../components/pdf/Default/QuotationPDF";
import InvoicePDF from "../components/pdf/Default/InvoicePDF";
import InvoicePDFRed from "../components/pdf/Red/InvoicePDF";
import DcPDF from "../components/pdf/Default/DcPDF";
import DcPDFRed from "../components/pdf/Red/DcPDF";
import SSQuotationPDF from "../components/pdf/Shell/SSQuotationPDF";

export const today = moment(new Date()).format("YYYY-MM-DD");
export var profileId = JSON.parse(localStorage.getItem("channel"));
export var profile = JSON.parse(localStorage.getItem("channelDetail"));
export const ActiveUser = JSON.parse(localStorage.getItem("user"));
export var companyList;

export const usd = JSON.parse(localStorage.getItem("usd"));
export var taxes = JSON.parse(localStorage.getItem("taxes"));
export var settings = JSON.parse(localStorage.getItem("settings"));

export const handleClickOpen = () => {
  return true;
};

export const Month = (m = 0) => {
  const td = moment(today).month(m);
  return td;
}


export const HelperDateCalc = (n, t) => {
  let stDate = moment(today).startOf(n).format("YYYY-MM-DD");
  let enDate = moment(today).endOf(n).format("YYYY-MM-DD");

  switch (n) {
    
    case 'week':
      // let now = moment(period.EndDate).endOf('week');
      console.log('Week is selected', stDate, enDate);   
      return { stDate, enDate };
        
      
    case 'month':
      console.log('Month is selected', stDate, enDate);
      return { stDate, enDate };
      
    case 'lmonth':
      let stLDate = moment(today).subtract(1, 'month').startOf('month').format("YYYY-MM-DD");
      let enLDate =moment(today).subtract(1, 'month').endOf('month').format("YYYY-MM-DD");
      console.log('Last Month is selected', stLDate, enLDate);
      return { stDate:stLDate, enDate:enLDate };
      
    case 'l3month':
      let st3LDate = moment(today).subtract(3, 'month').startOf('month').format("YYYY-MM-DD");
      let en3LDate =moment(today).subtract(1, 'month').endOf('month').format("YYYY-MM-DD");
      console.log('Last 3 Month is selected', st3LDate, en3LDate);
      return { stDate:st3LDate,  enDate:en3LDate };
    case 'year':
      // let now = moment(period.EndDate).endOf('week');
      console.log('Year is selected', stDate, enDate);   
      return { stDate:stDate,  enDate:enDate };
      
    case 'lyear':
      let stYLDate = moment(today).subtract(1,'year').startOf('year').format("YYYY-MM-DD");
      let enYLDate =moment(today).subtract(1, 'year').endOf('year').format("YYYY-MM-DD");
      console.log('Last Year is selected', stYLDate, enYLDate);
      return { stDate:stYLDate,  enDate:enYLDate };
      
    default:
      return { t, n };
      
    }
}

export const PDFCheck = (data, template = 'quotation', taxType= 'Standard', notax=false) => {
  if (template === 'invoice') {
    switch (data.Data[0].channel_id) {
      case 2:
        return <InvoicePDFRed data={data} ttype={taxType} /> 
      default:
        return <InvoicePDF data={data} ttype={taxType}/>     
    }
    
  }
  if (template === 'dc') {
    switch (data.Data[0].channel_id) {
      case 2:
        return <DcPDFRed data={data} /> 
      default:
        return <DcPDF data={data}  />     
    }
  }
  if (template === 'quotation') {
    switch (data.Data[0].channel_id) {
      case 2:
        return <QuotationPDFRed data={data} notax={notax} /> 
        case 9:
          return <SSQuotationPDF data={data} notax={notax}  /> 
      default:
        return <QuotationPDF data={data} notax={notax} />     
    }
  }
 }

export const FormattingNumber = (n) => {
  const options = { maximumFractionDigits: 2 };
  let formatingNumber = Intl.NumberFormat("en-US", options).format(n);

  return formatingNumber;
};


export const FormatDate = (d) => {
  return moment(d).format("YYYY-MM-DD")
  
};


export const StatusDelivery = () =>{
  return <>
    <MenuItem sx={{ textAlign: "left" }} value="N/A">
                  N/A
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="Ex Stock">
                  Ex Stock
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="1 Week">
                  1 Week
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="1 Week">
                  1-2 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="2 Weeks">
                  2 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="2 Weeks">
                  2-3 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="3 Weeks">
                  3 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="2 Weeks">
                  3-4 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="4 Weeks">
                  4 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="2 Weeks">
                  4-5 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="1 Month">
                  1 Month
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="2 Months">
                  2 Months
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="3 Months">
                  3 Months
                </MenuItem>
  </>
}

export const handleClose = (
  event?: React.SyntheticEvent | Event,
  reason?: string
) => {
  if (reason === "clickaway") {
    return;
  }

  return false;
};



// Local Storage Update Call
export const LocalUpdate = () =>{
  return new Promise(async (resolve, reject)=>{
    try {
      resolve(await magento.get('local/data/' + profile[0].id).then(response => {
        localStorage.setItem("companyList", JSON.stringify(response.data.companyList))
        localStorage.setItem("taxes", JSON.stringify(response.data.taxes))
        localStorage.setItem("settings", JSON.stringify(response.data.settings))
        localStorage.setItem("channelDetail", JSON.stringify(response.data.channel))
        companyList = JSON.parse(localStorage.getItem("companyList"));
        console.log('testcompany', companyList)
        profile = JSON.parse(localStorage.getItem("channelDetail"));
        taxes = JSON.parse(localStorage.getItem("taxes"));
        settings = JSON.parse(localStorage.getItem("settings"));
        return
      }));
    } catch (e) {
      reject(e);
    }  
  })
  
}



//** Data Get Call */
export const getCall = (url = 'channel/') => {
  return new Promise(async (resolve, reject)=>{
    try {
      resolve(await magento.get(url, {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",}).then(response => response.data));
    } catch (e) {
      reject(e);
    }  
  })
};

export const postCall = (url, data) => {
  return new Promise(async (resolve, reject)=>{
    try {
      resolve(await magento.post(url, data).then(response => response));
    } catch (e) {
      reject(e);
    }  
  })
};


export function CheckAuth(uID, display) {
  if(uID === 2 || uID === 4 || uID === 1) {
    return display
  } else {
    return ""
  }

}

export function CheckAdmin(uID) {
  if(uID === 2 || uID === 4 || uID === 1) {
    return true
  } else {
    return false
  }

}


export function WebCAll (url) {
magento.get(
    url
  ).then((response)=>{
    console.log(response)
    return (response);

  }).catch((error)=>{
    return error;
  })
}
