import React, { useContext } from "react";
import "./dashboard.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import {
  AppBar,
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Popover,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TableBody,
  Tab,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  AccountBalance,
  AccountBalanceWallet,
  AccountBalanceWalletOutlined,
  Article,
  AttachMoney,
  BusinessOutlined,
  DescriptionOutlined,
  DescriptionSharp,
  DoNotDisturb,
  DocumentScanner,
  MenuOutlined,
  MultipleStop,
  Receipt,
  Settings,
} from "@mui/icons-material";
import magento from "../api/magento";
import moment from "moment";
import CurrencyConvertor from "../components/CurrencyConvertor";
import PropTypes from "prop-types";
import { FormattingNumber, profile, ActiveUser, CheckAuth, CheckAdmin } from './../Helper/Helper';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Dashboard = ({ navigate }) => {
  const [data, setData] = React.useState([]);
  const [value, setValue] = React.useState(CheckAdmin(ActiveUser[0].id)? 0 : 1);
  const { useAuth } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menu, setMenu] = React.useState([]);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 
  const handleClick = (event, menu = event.currentTarget.id) => {
    setAnchorEl(event.currentTarget);
    setMenu(menu);
  };

  const handleClose = () => {
    setAnchorEl(false);
    setMenu([]);
  };

  const DataCall = async () => {
    await magento
      .get("data/channel/" + profile[0].id, {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      })
      .then((response) => {
        console.log(response.data)
        setData(response.data);
        return data;
      })
      .catch((error) => console.log("Error: ", error.message));
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box className="p3">
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  React.useEffect(() => {
    return DataCall();
  }, []);
  
  return (
    <div>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="primary"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuOutlined />
            </IconButton>
            <Typography
              variant="h5"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>
          </Toolbar>
        </AppBar>
        <Popover
          id={id}
          open={open}
          sx={{ p: 1 }}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {menu.map((item) => (
            <Box width={"200px"} sx={{ px: 1, py: 1 }}>
              <Link to={item.url}>
                <Typography width>{item.title}</Typography>
              </Link>
            </Box>
          ))}
        </Popover>
 
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ px: 2 }}
        >
          <Grid item xs={2} sm={4} md={4}>
            <Paper sx={{ p: 2, px: "7%" }}>
              <Typography variant="h6" style={{ textAlign: "left" }}>
                Customers & Sales Tasks
              </Typography>
              <Box sx={{ p: 0 }} display={"flex"}>
                <Paper
                  aria-describedby={id}
                  id="company"
                  onClick={(e) =>
                    handleClick(e, [
                      { title: "New Company", url: "/admin/company/create" },
                      { title: "Edit & View Company", url: "/admin/company" },
                    ])
                  }
                  elevation={3}
                  className="links"
                >
                  <BusinessOutlined fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Company
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="company"
                  onClick={() => navigate("/admin/settings")}
                  elevation={3}
                  className="links"
                >
                  <Settings fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Settings
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="taxes"
                  onClick={() => navigate("/admin/taxes")}
                  elevation={3}
                  className="links"
                >
                  <AccountBalanceWalletOutlined fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Tax
                  </Typography>
                </Paper>
              </Box>
              <Divider />
              <Box sx={{ p: 0 }} display={"flex"}>
                <Paper
                  aria-describedby={id}
                  id="quotations"
                  onClick={(e) =>
                    handleClick(e, [
                      {
                        title: "New Quotation",
                        url: "/admin/quotation/create",
                      },
                      {
                        title: "View & Edit Quotation",
                        url: "/admin/quotation",
                      },
                    ])
                  }
                  elevation={3}
                  className="links"
                >
                  <DescriptionSharp fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Quotations
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="rfqs"
                  onClick={(e) =>
                    handleClick(e, [
                      {
                        title: "New RFQ",
                        url: "/admin/rfq/create",
                      },
                      {
                        title: "View & Edit Quotation",
                        url: "/admin/rfqs",
                      },
                    ])
                  }
                  elevation={3}
                  className="links"
                >
                  <DocumentScanner fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    RFQ/PR
                  </Typography>
                </Paper>
              </Box>
              <Box sx={{ p: 0 }} display={"flex"}>
                <Paper
                  aria-describedby={id}
                  id="dc"
                  onClick={(e) =>
                    handleClick(e, [
                      {
                        title: "New Delivery Challan",
                        url: "/admin/dc/create",
                      },
                      {
                        title: "Edit & View Delivery Challan",
                        url: "/admin/delivery-challans",
                      },
                   
                    ])
                  }
                  elevation={3}
                  className="links"
                >
                  <DescriptionOutlined fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    DC
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="rejection"
                  onClick={(e) =>
                    handleClick(e, [
                      {
                        title: "Edit & View Rejections",
                        url: "/admin/rejections",
                      },
                    ])
                  }
                  elevation={3}
                  className="links"
                >
                  <DoNotDisturb fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Rejections
                  </Typography>
                </Paper>
              </Box>
              <Box sx={{ p: 0 }} display={"flex"}>
                <Paper
                  aria-describedby={id}
                  id="invoice"
                  onClick={(e) =>
                    handleClick(e, [
                      { title: "New Invoice", url: "/admin/invoice/create" },
                      { title: "Edit & View Invoice", url: "/admin/invoices" },
                      { title: "Edit & View Sales Invoice", url: "/admin/invoices/Sales" },
                      { title: "Edit & View Service Invoice", url: "/admin/invoices/Service" },
                    ])
                  }
                  elevation={3}
                  className="links"
                >
                  <Receipt fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Sales Invoice
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="bank_deposit"
                  elevation={3}
                  className="links"
                >
                  <AccountBalanceWallet fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Finance
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="payments"
                  onClick={(e) =>
                    handleClick(e, [
                      { title: "New Payment", url: "/admin/payment/create" },
                      { title: "Edit & View Payments", url: "/admin/payments" },
                    ])
                  }
                  elevation={3}
                  className="links"
                >
                  <AttachMoney fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Payments
                  </Typography>
                </Paper>
              </Box>
              <Box sx={{ p: 0 }} display={"flex"}>
                <Paper
                  variant="elevation"
                  elevation={0}
                  className="links link-white"
                ></Paper>
                <Paper
                  variant="elevation"
                  elevation={0}
                  className="links link-white"
                ></Paper>
                <Paper
                  elevation={3}
                  className="links"
                >
                  <AccountBalance fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Deposits
                  </Typography>
                </Paper>
              </Box>
              <Box sx={{ p: 0 }} display={"flex"}>
                <Paper
                  aria-describedby={id}
                  id="rejection"
                  elevation={3}
                  className="links"
                >
                  <Article fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Statements
                  </Typography>
                </Paper>
                <Paper
                  aria-describedby={id}
                  id="rejection"
                  elevation={3}
                  className="links"
                >
                  <MultipleStop fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Cdt. Returns
                  </Typography>
                </Paper>
                {ActiveUser[0].id && CheckAuth(ActiveUser[0].id, <>
                <Paper
                  aria-describedby={id}
                  id="reports"
                  elevation={3}
                  className="links"
                  onClick={(e) =>
                    handleClick(e, [
                      { title: "Invoices Reports", url: "/admin/reports/invoices" }
                      // { title: "Edit & View Invoice", url: "/admin/invoices" },
                      // { title: "Edit & View Sales Invoice", url: "/admin/invoices/Sales" },
                      // { title: "Edit & View Service Invoice", url: "/admin/invoices/Service" },
                    ])
                  }
                >
                  <DoNotDisturb fontSize="large" />
                  <Typography variant="small" fontSize={12}>
                    Reports
                  </Typography>
                </Paper>
                </>)}
              </Box>
            </Paper>
          </Grid>
          <Grid
            xs={2}
            sm={4}
            md={8}
            sx={{ p: 3, my: 0, py: 3, display: "flex", flexWrap: "wrap" }}
            spacing={{ xs: 1, md: 3 }}
          >
            <Grid item sm={4} md={4}>
              <Paper className="p1 mr10">
                <Typography variant="h6">Today USD Rate</Typography>
                <CurrencyConvertor CurrFrom="usd" />
              </Paper>
            </Grid>

            <Grid item xs={2} sm={4} md={4}>
              <Paper className="p1 mr10">
                <Typography variant="h6">Today AED Rate</Typography>
                <CurrencyConvertor CurrFrom="aed" />
              </Paper>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Paper className="p1">
                <Typography variant="h6">Today GBP Rate</Typography>
                <CurrencyConvertor CurrFrom="gbp" />
              </Paper>
            </Grid>

            <Box className="bgwhite"  >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="#000000"
                variant="fullWidth"
              >
                {ActiveUser[0].id && CheckAuth(ActiveUser[0].id, <Tab label={"Company"} />)}
                <Tab label={"Quotation"} />
                <Tab label={"DC"} />
                <Tab label={"Invoices"} />
                <Tab label={"Payments"} />
              </Tabs>
              {ActiveUser[0].id && CheckAuth(ActiveUser[0].id, <>
                <TabPanel value={value} index={0}>
                <TableContainer item component={Paper} fluid sx={{}}>
                  <Table sx={{ fontSize: '10px' }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Company Name</TableCell>
                        <TableCell sx={{ textAlign: 'right' }}>Balance Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.Company &&
                        data.Company.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0, },
                            }}
                          >
                            <TableCell component="th" scope="row" >
                              <Link target="_blank" to={`/admin/company/view/${row.id}`}>
                                {row.company}
                              </Link>
                            </TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>{FormattingNumber(row.balance_amt)}.00</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <br />
                </TableContainer>
              </TabPanel></>)}
              <TabPanel value={value} index={1}>
                <TableContainer item component={Paper} fluid sx={{}}>
                  <Table sx={{fontSize:'10px'}} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Quote#</TableCell>
                        <TableCell>Created at</TableCell>
                        <TableCell>Ref#</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Items Count</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.Quote &&
                        data.Quote.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0, fontSize:'10px' },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Link target="_blank" to={`/admin/quotation/view/${row.id}`}>
                                {row.quote_number}
                              </Link>
                            </TableCell>
                            <TableCell>
                              {" "}
                              {moment(row.create_at).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell>{row.ref_number}</TableCell>
                            <TableCell width={200}>
                              <Link
                                target="_blank"
                                to={"/admin/company/view/" + row.company_id}
                              >
                                {row.quote_to_company}
                              </Link>
                            </TableCell>
                            <TableCell>{row.total_items_count}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <br />
                </TableContainer>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <TableContainer component={Paper} fluid>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>DC#</TableCell>
                        <TableCell>PO#</TableCell>
                        <TableCell>Ref#</TableCell>
                        <TableCell>Quote#</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Item Count</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.Quote &&
                        data.DC.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row" align="left">
                              <Link target="_blank" to={`/admin/dc/view/${row.id}`}>
                                {row.dc_number}
                              </Link>
                            </TableCell>
                            <TableCell>{row.po_number}</TableCell>
                            <TableCell>{row.ref_number}</TableCell>
                            <TableCell>
                              {row.quote_id !== 0 ? (
                                <>
                                  <Link
                                    target="_blank"
                                    to={`/admin/quotation/view/${row.quote_id}`}
                                  >
                                    {row.quote_number}
                                  </Link>
                                </>
                              ) : (
                                ""
                              )}
                             
                            </TableCell>
                            <TableCell width={200}>
                              <Link
                                target="_blank"
                                to={"/admin/company/view/" + row.company_id}
                              >
                                {row.dc_to_company}
                              </Link>
                            </TableCell>
                            <TableCell>{row.total_items_count}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <br />
                </TableContainer>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <TableContainer component={Paper} fluid>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Invoice#</TableCell>
                        <TableCell>PO#</TableCell>
                        <TableCell>Ref#</TableCell>
                        <TableCell>Quote#</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell>Company</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.Quote &&
                        data.Invoices.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Link  target="_blank" to={`/admin/invoice/view/${row.id}`}>
                                {row.invoice_number}
                              </Link>
                            </TableCell>
                            <TableCell>{row.po_number}</TableCell>
                            <TableCell>{row.ref_number}</TableCell>
                            <TableCell>
                              <Link  target="_blank" to={`/admin/quotation/view/${row.id}`}>
                                {row.quotation_number}
                              </Link>
                            </TableCell>
                            <TableCell>
                              {" "}
                              {moment(row.invoice_create_at).format(
                                "DD-MM-YYYY"
                              )}
                            </TableCell>

                            <TableCell width={200}>
                              <Link
                               target="_blank"
                                to={"/admin/company/view/" + row.company_id}
                              >
                                {row.bill_to_company}
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <br />
                </TableContainer>{" "}
              </TabPanel>
              <TabPanel value={value} index={4}>
                <TableContainer item component={Paper} fluid sx={{}}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Payment#</TableCell>
                        <TableCell>Ref#</TableCell>
                        <TableCell>Receipt#</TableCell>
                        <TableCell>Amount#</TableCell>
                        <TableCell>Company</TableCell>
                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.Payments &&
                        data.Payments.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Link target="_blank" to={`/admin/payment/view/${row.id}`}>
                                {row.payment_number}
                              </Link>
                            </TableCell>
                            <TableCell>{row.ref_number}</TableCell>
                            <TableCell>{row.receipt_number}</TableCell>
                            <TableCell>{row.receipt_amount}</TableCell>
                            <TableCell>
                              {" "}
                              {moment(row.payment_date).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell width={200}>
                              <Link
                                target="_blank"
                                to={"/admin/company/view/" + row.company_id}
                              >
                                {row.company}
                              </Link>
                            </TableCell>
                            
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <br />
                </TableContainer>
              </TabPanel>
            </Box>
          </Grid>
          <Grid item xs={2} sm={4} md={6}></Grid>
          <Grid item xs={2} sm={4} md={12}></Grid>
        </Grid>
      </Box>
      {useAuth()}
    </div>
  );
};

export default Dashboard;
